import { Link } from "react-router-dom";
import "./header.css";

export default function Header() {
  return (
    <div className="header">
      <div className="header-container">
          <div className="header-left">
            <div className="headerTitles">
              <h1 className="header-lg">
              <span class="header-home-hg">Home</span> for design writers and readers
              </h1>
              <h2 className="header-md">
              One stop blog for designers to learn and share everything related to UX Design.<br/><br/>
              <h3 className = "header-sm">Newbie? An Experienced Designer? Doesn't matter! Share your knowledge with us!</h3>
              </h2>
              <Link className="link header-btn" to="/write">
              <div className="header-btn"><span className="header-btn-txt">Start Writing!</span></div>
              </Link>
            </div>
            
          </div>
          <div className="header-right">
          <svg className="header-img" width="414" height="430" viewBox="0 0 414 430" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M152.788 301.595C148.721 301.578 144.648 301.415 140.573 301.081C95.9 297.419 44.15 276.088 21.907 237.25C2.46203 203.297 -5.17298 159.339 3.68203 121.472C12.005 85.8772 37.808 57.0432 73.941 46.0952C114.577 33.7822 159.489 48.7422 199.476 31.9261C257.675 7.45116 330.824 26.8891 371.613 73.4551C407.775 114.742 416.527 181.315 405.176 232.897C399.438 258.966 384.347 284.074 360.323 297.199C341.209 307.642 318.104 309.7 296.247 306.979C278.673 304.79 262.493 299.059 245.588 294.342C232.036 290.561 220.917 292.53 207.048 295.274C189.196 298.806 171.046 301.671 152.788 301.595Z" fill="#3466F6"/>
<path d="M162.763 276.684C159.472 276.67 156.175 276.539 152.877 276.269C116.73 273.306 74.8558 256.044 56.8568 224.618C41.1218 197.144 34.9428 161.574 42.1078 130.933C48.8418 102.13 69.7228 78.7982 98.9608 69.9392C131.843 59.9762 168.185 72.0822 200.541 58.4762C247.634 38.6712 306.825 54.3982 339.829 92.0802C369.092 125.487 376.174 179.357 366.988 221.096C362.345 242.191 350.134 262.507 330.696 273.128C315.229 281.579 296.532 283.244 278.845 281.041C264.624 279.271 251.534 274.633 237.854 270.816C226.888 267.756 217.891 269.349 206.668 271.57C192.224 274.428 177.536 276.746 162.763 276.684Z" stroke="white" stroke-width="1.946" stroke-miterlimit="10" stroke-dasharray="3.85 3.85"/>
<path d="M391.522 414.665C391.522 415.403 383.645 416.002 373.93 416.002C364.214 416.002 356.338 415.403 356.338 414.665C356.338 413.926 364.214 413.327 373.93 413.327C383.645 413.327 391.522 413.926 391.522 414.665Z" fill="#505058"/>
<path d="M388.452 376.779H359.408L362.243 413.549H385.617L388.452 376.779Z" fill="#FDFDFD"/>
<path d="M391.522 379.211H356.338V374.899H391.522V379.211Z" fill="#8C8C8D"/>
<path d="M384.613 371.021H363.247C361.127 371.021 359.408 372.74 359.408 374.861V374.899H388.452V374.861C388.452 372.74 386.733 371.021 384.613 371.021Z" fill="#595963"/>
<path d="M386.835 397.729L387.522 388.821H360.334L361.022 397.729H386.835Z" fill="#DAC4F9"/>
<g opacity="0.5">
<path opacity="0.5" d="M388.451 374.9V374.862C388.451 372.741 386.732 371.021 384.612 371.021H374.031C376.151 371.021 377.871 372.741 377.871 374.862V374.9H380.94V379.211H377.683L375.036 413.549H385.616L388.264 379.211H391.521V374.9H388.451Z" fill="#B0B0B0"/>
</g>
<g opacity="0.565217">
<path d="M376.163 364.083C376.163 364.083 361.1 353.85 370.775 338.976C380.45 324.102 378.031 318.933 374.645 314.745C382.868 324.102 381.066 334.763 376.163 341.525C371.259 348.288 370.481 357.491 376.163 364.083Z" fill="#AAAAB1"/>
</g>
<path d="M331.652 167.294L333.76 171.566L338.474 172.25L335.063 175.576L335.868 180.272L331.652 178.055L327.435 180.272L328.241 175.576L324.83 172.25L329.544 171.566L331.652 167.294Z" fill="#F2ECFF"/>
<path d="M392.35 161.325L395.294 167.291L401.876 168.248L397.114 172.89L398.238 179.447L392.35 176.351L386.462 179.447L387.586 172.89L382.824 168.248L389.406 167.291L392.35 161.325Z" fill="white"/>
<path d="M373.928 250.719L375.57 254.048L379.245 254.582L376.586 257.174L377.213 260.833L373.928 259.106L370.641 260.833L371.268 257.174L368.609 254.582L372.284 254.048L373.928 250.719Z" fill="#F1ECFA"/>
<path d="M315.303 228.162L318.246 234.129L324.829 235.085L320.066 239.728L321.19 246.284L315.303 243.188L309.415 246.284L310.54 239.728L305.777 235.085L312.359 234.129L315.303 228.162Z" fill="white"/>
<path d="M327.892 202.959C332.765 185.495 350.872 175.289 368.336 180.161C385.8 185.034 396.006 203.142 391.134 220.605C386.261 238.069 368.154 248.275 350.69 243.403C333.226 238.53 323.02 220.423 327.892 202.959Z" fill="#FFBE9D"/>
<path d="M350.69 243.403C362.287 246.638 374.163 243.219 382.262 235.447C376.777 236.918 370.839 236.99 364.972 235.352C347.508 230.479 337.301 212.372 342.174 194.909C343.811 189.042 346.947 183.999 351.045 180.066C340.202 182.972 331.128 191.362 327.892 202.959C323.02 220.423 333.226 238.53 350.69 243.403Z" fill="#EB996E"/>
<path d="M368.337 180.161C366.701 179.705 365.06 179.381 363.425 179.182C362.568 182.253 362.102 185.484 362.102 188.826C362.102 205.915 374.065 220.2 390.07 223.788C390.475 222.757 390.83 221.695 391.135 220.605C396.007 203.141 385.801 185.034 368.337 180.161Z" fill="#FF8282"/>
<path d="M326.796 209.081C314.06 204.119 306.139 199.631 306.804 197.245C307.911 193.277 332.358 196.632 361.409 204.737C390.46 212.844 413.113 222.632 412.006 226.6C411.343 228.977 402.299 228.726 388.905 226.397" stroke="#7827C9" stroke-width="2" stroke-miterlimit="10"/>
<path d="M78.9235 139.5C48.0525 141.372 24.0575 159.873 25.3275 180.821C26.5985 201.769 52.6535 217.232 83.5245 215.36C83.5475 215.358 83.5695 215.356 83.5935 215.354C86.6315 215.168 88.7565 212.234 88.0055 209.284C87.6405 207.85 87.4345 206.347 87.4105 204.795C87.2535 194.81 94.9775 186.229 104.923 185.33C112.14 184.679 118.735 188.073 122.541 193.595C124.419 196.319 128.411 196.511 130.442 193.897C135.083 187.924 137.549 181.123 137.119 174.039C135.849 153.091 109.793 137.628 78.9235 139.5Z" fill="#595963"/>
<path d="M78.7174 136.111C47.8474 137.983 23.8524 156.483 25.1224 177.43C26.3934 198.379 52.4484 213.842 83.3194 211.969C83.3414 211.968 83.3654 211.966 83.3884 211.965C86.4264 211.778 88.5504 208.843 87.7994 205.894C87.4344 204.46 87.2304 202.957 87.2054 201.405C87.0484 191.42 94.7724 182.84 104.718 181.941C111.936 181.289 118.53 184.682 122.336 190.204C124.215 192.928 128.206 193.122 130.237 190.508C134.877 184.534 137.343 177.733 136.913 170.65C135.643 149.702 109.587 134.238 78.7174 136.111Z" fill="#F1F1F7"/>
<path d="M67.8147 195.495C68.0407 199.216 65.2077 202.416 61.4857 202.641C57.7637 202.867 54.5647 200.033 54.3387 196.312C54.1137 192.591 56.9467 189.391 60.6687 189.165C64.3897 188.939 67.5887 191.773 67.8147 195.495Z" fill="#7827C9"/>
<path d="M49.426 182.718C49.652 186.439 46.818 189.64 43.096 189.865C39.375 190.091 36.176 187.257 35.95 183.535C35.725 179.814 38.559 176.614 42.28 176.389C46.001 176.163 49.2 178.996 49.426 182.718Z" fill="#7827C9"/>
<path d="M54.8323 160.372C55.0593 164.094 52.2253 167.293 48.5033 167.518C44.7823 167.745 41.5823 164.911 41.3563 161.189C41.1313 157.469 43.9653 154.268 47.6863 154.043C51.4073 153.817 54.6063 156.651 54.8323 160.372Z" fill="#7827C9"/>
<path d="M77.6243 148.295C77.8503 152.017 75.0163 155.216 71.2953 155.442C67.5733 155.668 64.3743 152.834 64.1473 149.112C63.9223 145.392 66.7563 142.191 70.4783 141.966C74.1983 141.74 77.3983 144.574 77.6243 148.295Z" fill="#7827C9"/>
<path d="M103.294 148.625C103.52 152.347 100.686 155.547 96.9652 155.772C93.2432 155.998 90.0432 153.164 89.8172 149.442C89.5922 145.722 92.4262 142.521 96.1482 142.296C99.8682 142.07 103.068 144.904 103.294 148.625Z" fill="#7827C9"/>
<path d="M124.962 160.837C125.188 164.558 122.354 167.758 118.632 167.983C114.911 168.209 111.711 165.375 111.485 161.654C111.26 157.933 114.094 154.733 117.815 154.508C121.536 154.281 124.735 157.115 124.962 160.837Z" fill="#7827C9"/>
<path d="M53.6636 159.34C53.7576 160.897 52.5726 162.235 51.0166 162.329C49.4596 162.423 48.1216 161.238 48.0266 159.682C47.9336 158.125 49.1176 156.787 50.6746 156.693C52.2306 156.599 53.5686 157.783 53.6636 159.34Z" fill="white"/>
<path d="M76.4694 147.504C76.5644 149.061 75.3784 150.399 73.8234 150.493C72.2664 150.587 70.9284 149.402 70.8334 147.846C70.7394 146.289 71.9244 144.951 73.4804 144.857C75.0364 144.763 76.3754 145.947 76.4694 147.504Z" fill="white"/>
<path d="M100.617 146.901C100.712 148.458 99.5257 149.796 97.9697 149.89C96.4127 149.985 95.0747 148.799 94.9797 147.243C94.8867 145.686 96.0707 144.348 97.6277 144.255C99.1837 144.16 100.522 145.344 100.617 146.901Z" fill="white"/>
<path d="M46.5787 180.727C46.6737 182.284 45.4877 183.622 43.9317 183.716C42.3757 183.81 41.0367 182.625 40.9427 181.069C40.8487 179.512 42.0337 178.174 43.5897 178.08C45.1457 177.986 46.4837 179.17 46.5787 180.727Z" fill="white"/>
<path d="M66.5904 193.536C66.6854 195.093 65.4994 196.432 63.9434 196.525C62.3874 196.62 61.0484 195.434 60.9544 193.879C60.8604 192.322 62.0454 190.984 63.6014 190.89C65.1574 190.795 66.4954 191.98 66.5904 193.536Z" fill="white"/>
<path d="M133.445 136.093C133.445 136.093 142.655 131.059 143.245 123.28C143.208 118.681 141.211 114.908 141.211 114.908L139.95 119.647C139.95 119.647 139.257 112.203 133.942 108.608C128.628 105.011 129.935 106.021 129.935 106.021C129.935 106.021 134.398 111.944 129.444 121.766C124.491 131.588 130.742 134.992 130.742 134.992L133.445 136.093Z" fill="#7827C9"/>
<path d="M132.875 132.903C132.875 132.903 131.209 124.36 135.017 119.77C130.067 126.045 129.045 131.087 130.288 135.16L132.875 132.903Z" fill="#CABC15"/>
<path d="M128.427 134.592C129.239 132.949 131.18 132.212 132.876 132.904L132.906 132.916L132.937 132.929C134.633 133.619 135.508 135.502 134.941 137.243L128.885 155.849L124.324 153.993L119.764 152.135L128.427 134.592Z" fill="white"/>
<path d="M119.06 156.293L126.774 159.434C127.814 159.857 129 159.357 129.424 158.317L129.443 158.272C129.867 157.231 129.367 156.045 128.326 155.621L120.611 152.48C119.571 152.057 118.384 152.557 117.96 153.597L117.942 153.642C117.518 154.683 118.019 155.869 119.06 156.293Z" fill="#CDCDCD"/>
<path d="M119.677 156.545L122.915 157.863L126.154 159.182C126.154 159.182 128.613 159.763 121.02 176.391C115.377 188.748 100.863 219.742 92.4984 236.303C91.7154 237.852 89.3694 236.897 89.8914 235.241C95.4734 217.549 106.738 185.231 111.331 172.445C117.513 155.244 119.677 156.545 119.677 156.545Z" fill="#2B2B31"/>
<path d="M102.404 216.058C98.7892 223.596 95.3002 230.759 92.5002 236.303C91.7162 237.852 89.3722 236.897 89.8932 235.242C91.7622 229.319 94.2682 221.755 96.9472 213.836L102.404 216.058Z" fill="white"/>
<g opacity="0.5">
<path opacity="0.5" d="M101.26 201.195V201.194C113.159 186.466 117.635 158.409 117.655 158.287C116.333 160.21 114.295 164.198 111.331 172.445C109.086 178.693 105.249 189.605 101.26 201.195Z" fill="white"/>
</g>
<path d="M316.876 94.0355V87.0265H313.778C313.192 83.6975 311.832 80.6445 309.888 78.0435L312.346 75.5855L307.39 70.6295L304.841 73.1775C302.232 71.3955 299.199 70.2015 295.932 69.7295V66.0695H288.923V69.8675C285.79 70.4585 282.91 71.7405 280.426 73.5445L277.945 71.0645L272.989 76.0185L275.502 78.5335C273.666 81.1435 272.417 84.1855 271.909 87.4775H268.43V94.4865H271.986C272.561 97.6985 273.855 100.652 275.698 103.19L272.96 105.927L277.916 110.883L280.689 108.111C283.231 109.898 286.182 111.129 289.374 111.66V115.443H296.383V111.66C299.565 111.131 302.506 109.906 305.042 108.13L307.36 110.449L312.316 105.492L310.039 103.215C311.979 100.554 313.307 97.4315 313.839 94.0355H316.876ZM292.878 102.651C286.294 102.651 280.956 97.3135 280.956 90.7295C280.956 84.1445 286.294 78.8075 292.878 78.8075C299.463 78.8075 304.801 84.1445 304.801 90.7295C304.801 97.3135 299.463 102.651 292.878 102.651Z" fill="#8C4ACF"/>
<path d="M344.823 120.615V116.572H343.036C342.698 114.651 341.913 112.891 340.792 111.39L342.209 109.972L339.35 107.112L337.879 108.583C336.375 107.556 334.625 106.866 332.74 106.595V104.482H328.697V106.674C326.89 107.015 325.228 107.754 323.795 108.795L322.364 107.363L319.505 110.223L320.955 111.673C319.896 113.18 319.175 114.934 318.882 116.832H316.875V120.876H318.927C319.258 122.729 320.004 124.433 321.068 125.897L319.489 127.477L322.347 130.335L323.947 128.735C325.414 129.767 327.116 130.477 328.958 130.783V132.966H333V130.783C334.835 130.478 336.533 129.772 337.997 128.747L339.333 130.084L342.192 127.226L340.878 125.911C341.998 124.376 342.764 122.574 343.071 120.615H344.823ZM330.979 125.586C327.18 125.586 324.101 122.507 324.101 118.709C324.101 114.91 327.18 111.831 330.979 111.831C334.778 111.831 337.857 114.91 337.857 118.709C337.857 122.507 334.778 125.586 330.979 125.586Z" fill="#7827C9"/>
<path d="M408.523 68.2555V53.8615H402.162C400.958 47.0245 398.166 40.7535 394.174 35.4115L399.221 30.3645L389.044 20.1865L383.808 25.4215C378.449 21.7615 372.223 19.3095 365.513 18.3425V10.8245H351.119V18.6255C344.686 19.8385 338.77 22.4705 333.669 26.1745L328.575 21.0805L318.398 31.2575L323.558 36.4175C319.787 41.7795 317.222 48.0265 316.181 54.7875H309.035V69.1795H316.338C317.517 75.7755 320.176 81.8435 323.963 87.0535L318.338 92.6785L328.514 102.855L334.209 97.1615C339.43 100.83 345.49 103.358 352.047 104.449V112.218H366.439V104.449C372.972 103.362 379.013 100.848 384.221 97.2015L388.983 101.962L399.161 91.7855L394.483 87.1085C398.467 81.6425 401.194 75.2285 402.287 68.2555H408.523ZM359.243 85.9495C345.721 85.9495 334.759 74.9895 334.759 61.4675C334.759 47.9455 345.721 36.9835 359.243 36.9835C372.764 36.9835 383.725 47.9455 383.725 61.4675C383.725 74.9895 372.764 85.9495 359.243 85.9495Z" fill="#7827C9"/>
<path d="M373.146 61.5204C373.146 69.4554 366.712 75.8884 358.778 75.8884C350.843 75.8884 344.412 69.4554 344.412 61.5204C344.412 53.5854 350.843 47.1544 358.778 47.1544C366.712 47.1544 373.146 53.5854 373.146 61.5204Z" fill="#8C4ACF"/>
<path d="M232.675 8.29579C229.255 5.04379 224.934 2.82479 220.485 1.34379C220.455 1.33279 220.387 1.31479 220.285 1.28679C216.256 0.207788 212.007 0.207788 207.978 1.28679C207.876 1.31479 207.807 1.33279 207.778 1.34379C203.329 2.82479 199.007 5.04379 195.587 8.29579C185.407 17.9748 187.061 35.5428 196.976 44.8128C199.756 47.4128 200.978 50.7698 201.829 54.4698C202.085 55.5848 203.561 67.2098 203.046 67.2098H214.131H225.216C224.702 67.2098 226.177 55.5848 226.434 54.4698C227.285 50.7698 228.506 47.4128 231.287 44.8128C241.201 35.5428 242.855 17.9748 232.675 8.29579Z" fill="#FFD952"/>
<g opacity="0.3">
<path opacity="0.3" d="M210.146 54.4688C209.294 50.7688 208.073 47.4118 205.291 44.8118C195.377 35.5418 193.725 17.9738 203.905 8.29484C207.325 5.04284 211.646 2.82384 216.095 1.34283C216.125 1.33184 216.193 1.31384 216.294 1.28584C216.955 1.10984 217.622 0.972836 218.291 0.853837C214.871 0.245835 211.349 0.383835 207.979 1.28584C207.877 1.31384 207.809 1.33184 207.779 1.34283C203.331 2.82384 199.007 5.04284 195.587 8.29484C185.408 17.9738 187.061 35.5418 196.976 44.8118C199.756 47.4118 200.978 50.7688 201.83 54.4688C202.085 55.5838 203.561 67.2088 203.046 67.2088H211.364C211.877 67.2088 210.403 55.5838 210.146 54.4688Z" fill="#FFF6E7"/>
</g>
<path d="M214.132 67.2098V40.0988L218.904 37.3478C220.482 36.4388 220.166 34.0758 218.406 33.6108L209.723 31.3208C208.242 30.9308 208.074 28.8948 209.471 28.2668L214.132 26.1698V16.9678" stroke="#FDFDFD" stroke-width="2.239" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M227.423 70.9418C227.423 71.7658 226.755 72.4338 225.931 72.4338H201.555C200.731 72.4338 200.062 71.7658 200.062 70.9418C200.062 70.1178 200.731 69.4498 201.555 69.4498H225.931C226.755 69.4498 227.423 70.1178 227.423 70.9418Z" fill="#2B2B31"/>
<path d="M227.423 75.4178C227.423 76.2418 226.755 76.9108 225.931 76.9108H201.555C200.731 76.9108 200.062 76.2418 200.062 75.4178C200.062 74.5938 200.731 73.9268 201.555 73.9268H225.931C226.755 73.9268 227.423 74.5938 227.423 75.4178Z" fill="#2B2B31"/>
<path d="M227.423 79.8958C227.423 80.7198 226.755 81.3878 225.931 81.3878H201.555C200.731 81.3878 200.062 80.7198 200.062 79.8958C200.062 79.0718 200.731 78.4038 201.555 78.4038H225.931C226.755 78.4038 227.423 79.0718 227.423 79.8958Z" fill="#2B2B31"/>
<path d="M213.742 91.8348C218.551 91.8348 222.449 87.9368 222.449 83.1288H205.037C205.037 87.9368 208.934 91.8348 213.742 91.8348Z" fill="#2B2B31"/>
<path d="M88.9322 90.5258L78.9682 93.0048C77.5232 93.3638 76.0602 92.4838 75.7012 91.0388L74.7422 87.1818L89.9392 83.4028L90.8982 87.2578C91.2582 88.7038 90.3772 90.1668 88.9322 90.5258Z" fill="#A665DF"/>
<path d="M125.953 78.6438L39.8863 100.049C38.3913 100.42 36.8803 99.5108 36.5093 98.0178L22.3153 40.9428C21.9443 39.4498 22.8533 37.9378 24.3473 37.5668L110.414 16.1618C111.907 15.7898 113.42 16.6998 113.791 18.1928L127.985 75.2668C128.357 76.7608 127.446 78.2728 125.953 78.6438Z" fill="#A665DF"/>
<path d="M123.403 68.7809L121.004 75.6769L44.6389 94.6679L37.8579 90.0549L23.7949 33.5059L109.339 12.2299L123.403 68.7809Z" fill="#DAC4F9"/>
<path d="M79.5349 79.6899L37.8579 90.0559L23.7949 33.5049L65.4709 23.1399L79.5349 79.6899Z" fill="white"/>
<path d="M63.3864 29.9569L29.1424 38.4739L28.6504 36.4959L62.8944 27.9799L63.3864 29.9569Z" fill="#B0B0B0"/>
<path d="M64.9606 36.2849L30.7176 44.8009L30.2266 42.8229L64.4686 34.3069L64.9606 36.2849Z" fill="#B0B0B0"/>
<path d="M66.5328 42.6119L32.2908 51.1279L31.7988 49.1509L66.0418 40.6339L66.5328 42.6119Z" fill="#B0B0B0"/>
<path d="M68.109 48.9388L33.865 57.4568L33.373 55.4778L67.617 46.9608L68.109 48.9388Z" fill="#B0B0B0"/>
<path d="M69.6813 55.2658L35.4383 63.7838L34.9473 61.8048L69.1893 53.2878L69.6813 55.2658Z" fill="#B0B0B0"/>
<path d="M71.2535 61.5938L37.0115 70.1108L36.5195 68.1328L70.7625 59.6168L71.2535 61.5938Z" fill="#B0B0B0"/>
<path d="M72.8278 67.9218L38.5848 76.4378L38.0938 74.4598L72.3358 65.9438L72.8278 67.9218Z" fill="#B0B0B0"/>
<path d="M74.402 74.2489L40.16 82.7649L39.668 80.7879L73.911 72.2709L74.402 74.2489Z" fill="#B0B0B0"/>
<path d="M81.7251 79.1459L123.402 68.7809L109.338 12.2299L67.6621 22.5949L81.7251 79.1459Z" fill="white"/>
<path d="M72.6961 27.6418L106.939 19.1248L106.447 17.1478L72.2051 25.6638L72.6961 27.6418Z" fill="#B0B0B0"/>
<path d="M74.2713 33.9689L108.513 25.4519L108.022 23.4749L73.7793 31.9909L74.2713 33.9689Z" fill="#B0B0B0"/>
<path d="M75.8426 40.2958L110.086 31.7788L109.594 29.8038L75.3516 38.3178L75.8426 40.2958Z" fill="#B0B0B0"/>
<path d="M77.4168 46.6229L111.66 38.1069L111.169 36.1309L76.9258 44.6449L77.4168 46.6229Z" fill="#B0B0B0"/>
<path d="M78.99 52.9499L113.232 44.4339L112.741 42.4579L78.498 50.9739L78.99 52.9499Z" fill="#B0B0B0"/>
<path d="M80.5633 59.2789L114.806 50.7619L114.314 48.7849L80.0723 57.3009L80.5633 59.2789Z" fill="#B0B0B0"/>
<path d="M82.1385 65.6058L116.38 57.0888L115.889 55.1118L81.6465 63.6278L82.1385 65.6058Z" fill="#B0B0B0"/>
<path d="M83.7108 71.9329L117.955 63.4159L117.463 61.4409L83.2188 69.9549L83.7108 71.9329Z" fill="#B0B0B0"/>
<path d="M86.3204 87.2198L79.7264 88.8598C78.2814 89.2188 76.8194 88.3388 76.4604 86.8948L75.8574 84.4738L87.6834 81.5328L88.2864 83.9528C88.6454 85.3978 87.7654 86.8608 86.3204 87.2198Z" fill="#DAC4F9"/>
<path d="M78.2313 283.964C76.2224 290.152 74.421 296.03 72.8376 301.61C72.5782 302.487 72.3278 303.365 72.0889 304.233C70.0606 311.481 68.381 318.207 66.9894 324.459C66.7359 325.607 66.4919 326.745 66.259 327.864C65.0085 333.742 64.0422 339.177 63.3007 344.194C63.0664 345.713 62.8639 347.183 62.6835 348.616C61.9762 354.095 61.5492 359.039 61.351 363.475L58.6961 363.326C58.9088 358.811 59.3482 353.808 60.0684 348.26C60.2548 346.898 60.4444 345.496 60.6637 344.066C61.4468 338.862 62.4637 333.22 63.7676 327.103C64.1061 325.53 64.4568 323.917 64.8401 322.276C66.1934 316.332 67.813 309.963 69.7358 303.149C70.2258 301.374 70.7474 299.57 71.2907 297.738C72.6533 293.077 74.157 288.222 75.8021 283.167L76.2153 283.3L78.1526 283.939L78.2313 283.964Z" fill="#595963"/>
<path d="M64.541 284.834C73.5787 294.02 71.3357 304.156 71.3357 304.156C71.3357 304.156 62.0282 299.557 60.5262 286.759C60.2631 284.516 62.9566 283.223 64.541 284.834Z" fill="#595963"/>
<path d="M88.0227 292.276C75.3451 294.58 71.3376 304.156 71.3376 304.156C71.3376 304.156 81.5951 305.758 90.1958 296.163C91.7033 294.48 90.2447 291.873 88.0227 292.276Z" fill="#595963"/>
<path d="M56.6248 309.334C66.5383 317.564 65.3281 327.876 65.3281 327.876C65.3281 327.876 55.6054 324.235 52.8253 311.653C52.3378 309.447 54.8868 307.891 56.6248 309.334Z" fill="#595963"/>
<path d="M80.7359 314.378C68.3533 317.943 65.3293 327.877 65.3293 327.877C65.3293 327.877 75.6963 328.436 83.288 318.025C84.6191 316.201 82.9072 313.753 80.7359 314.378Z" fill="#595963"/>
<path d="M51.2717 330.908C61.7674 338.382 61.323 348.754 61.323 348.754C61.323 348.754 51.3581 345.843 47.6531 333.502C47.0041 331.337 49.4311 329.598 51.2717 330.908Z" fill="#595963"/>
<path d="M75.6866 334.154C63.6027 338.627 61.3221 348.755 61.3221 348.755C61.3221 348.755 71.7009 348.548 78.5023 337.603C79.6943 335.685 77.8057 333.371 75.6866 334.154Z" fill="#595963"/>
<path d="M86.0029 266.925C85.4984 279.801 76.5757 285.11 76.5757 285.11C76.5757 285.11 73.5541 275.178 81.8512 265.318C83.3051 263.589 86.0912 264.667 86.0029 266.925Z" fill="#595963"/>
<path d="M90.3435 414.666C90.3435 415.404 78.5195 416.003 63.9335 416.003C49.3465 416.003 37.5215 415.404 37.5215 414.666C37.5215 413.927 49.3465 413.328 63.9335 413.328C78.5195 413.328 90.3435 413.927 90.3435 414.666Z" fill="#505058"/>
<path d="M76.7739 414.069H49.0859V359.295H76.7739V414.069Z" fill="#A179F8"/>
<path d="M76.7739 414.069H65.7109V359.295H76.7739V414.069Z" fill="#FFEA9D"/>
<path d="M249.385 192.668L249.544 192.725C259.583 196.311 266.485 203.932 270.458 213.823L308.469 303.205L277.099 306.942L250.544 241.858L249.385 192.668Z" fill="#FF7E6D"/>
<path d="M308.471 303.205L308.533 303.379C312.093 313.235 307.829 324.206 298.547 329.071L235.124 362.317L234.811 361.508C229.223 347.072 235.068 330.723 248.54 323.101L277.101 306.942L308.471 303.205Z" fill="#FF7E6D"/>
<g opacity="0.3">
<path opacity="0.3" d="M295.076 323.569C295.076 325.91 295.661 328.112 296.683 330.047L298.545 329.071C305.865 325.234 310.045 317.597 309.746 309.707C309.49 309.693 309.237 309.668 308.977 309.668C301.3 309.668 295.076 315.891 295.076 323.569Z" fill="#542524"/>
</g>
<path d="M277.1 306.942L295.078 296.748" stroke="#542524" stroke-width="1.336" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M271.578 213.916C267.983 204.966 261.067 197.809 252.348 193.869L252.349 193.859L252.223 193.812C251.345 193.421 250.456 193.05 249.545 192.724L249.385 192.667L249.387 192.758L244.508 190.942C240.533 189.464 236.094 191.32 234.355 195.187L220.459 224.822L250.37 234.471L250.544 241.857L258.067 260.296C268.109 251.884 278.185 249.152 285.903 248.587L271.578 213.916Z" fill="#E8E8E8"/>
<path d="M182.359 191.644L182.196 191.687C172.861 194.984 164.246 201.366 159.425 210.873L113.866 296.611L144.791 303.067L176.918 240.546L182.359 191.644Z" fill="#FF7E6D"/>
<path d="M114.051 296.627L113.974 296.795C109.569 306.303 112.86 317.604 121.683 323.259L181.968 361.907L182.351 361.128C189.174 347.234 184.777 330.438 172.02 321.67L144.976 303.084L114.051 296.627Z" fill="#FF7E6D"/>
<g opacity="0.3">
<path opacity="0.3" d="M125.617 318.081C125.413 320.413 124.638 322.555 123.451 324.394L121.681 323.259C114.724 318.799 111.225 310.827 112.211 302.993C112.467 303.001 112.721 302.998 112.98 303.021C120.628 303.69 126.286 310.432 125.617 318.081Z" fill="#542524"/>
</g>
<path d="M144.976 303.085L127.955 291.363" stroke="#542524" stroke-width="1.336" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M196.117 191.147C195.937 189.513 194.343 188.425 192.756 188.851L181.787 191.796L181.785 191.804C171.654 194.579 163.154 201.496 158.398 210.877L141.105 244.166C148.745 245.403 158.545 249.002 167.816 258.257L176.917 240.546L178.623 225.213L183.109 226.999C192.781 217.719 197.588 204.469 196.117 191.147Z" fill="#E8E8E8"/>
<path d="M232.523 146.251C232.523 146.251 238.61 127.163 229.716 121.619C224.063 118.714 220.975 122.332 220.975 122.332C220.975 122.332 218.318 108.836 200.001 113.294C188.136 116.182 184.393 110.066 184.393 110.066C184.393 110.066 184.282 126.985 192.453 132.017C198.369 137.09 196.1 150.802 196.1 150.802L232.523 146.251Z" fill="#2B2B31"/>
<path d="M286.482 395.705C286.482 395.705 319.459 396.695 328.662 405.191C338.34 414.126 348.495 428.078 306.829 427.809C265.164 427.541 228.226 416.072 193.28 419.712C158.334 423.351 129.657 429.627 121.303 428.234C112.949 426.843 172.494 398.317 172.494 398.317L283.044 402.375L286.482 395.705Z" fill="#505058"/>
<path d="M255.64 321.53L214.068 317.354L172.495 321.53C172.495 321.53 170.026 341.174 161.998 351.652L214.068 370.35L266.136 351.652C258.108 341.174 255.64 321.53 255.64 321.53Z" fill="#CABC15"/>
<path d="M212.424 369.76C214.065 370.349 214.067 370.35 214.067 370.35C214.067 370.35 214.069 370.349 215.706 369.761C225.286 347.723 222.993 322.996 222.426 318.193L214.067 317.353L203.367 318.427C203.154 323.709 202.797 347.863 212.424 369.76Z" fill="#CABC15"/>
<path d="M172.258 323.132C172.26 323.132 216.003 342.426 258.248 334.04C256.351 327.183 255.639 321.528 255.639 321.528L214.067 317.353L180.672 320.707L172.258 323.132Z" fill="#995330"/>
<path d="M192.451 132.016C198.367 137.089 196.098 150.801 196.098 150.801L203.839 149.835C211.786 142.763 220.758 133.808 219.455 130.434C217.172 124.522 210.577 118.621 198.419 117.947C186.26 117.272 184.391 110.067 184.391 110.067C184.391 110.067 184.28 126.985 192.451 132.016Z" fill="#505058"/>
<path d="M212.559 179.919L178.19 192.447L172.496 321.529C172.496 321.529 184.644 327.225 214.068 327.225V181.922L212.559 179.919Z" fill="white"/>
<path d="M215.577 179.919L249.945 192.447L255.64 321.529C255.64 321.529 243.491 327.225 214.068 327.225V181.922L215.577 179.919Z" fill="white"/>
<path d="M252.189 243.338C252.189 243.338 251.258 242.035 249.945 239.592L251.103 241.638C251.103 241.638 238.027 314.213 203.072 326.937C206.433 327.116 210.086 327.224 214.067 327.224C243.49 327.224 255.639 321.528 255.639 321.528L252.189 243.338Z" fill="#D4D4D4"/>
<path d="M214.159 195.843H213.973C208.892 195.843 204.773 191.725 204.773 186.645V164.683H223.357V186.645C223.357 191.725 219.239 195.843 214.159 195.843Z" fill="#FF7E6D"/>
<path d="M223.209 181.622C223.262 181.622 223.306 181.616 223.357 181.616V164.684H214.683L204.773 168.263C204.773 168.263 208.462 181.622 223.209 181.622Z" fill="#542524"/>
<path d="M235.682 151.487C235.682 154.783 233.011 157.454 229.715 157.454C226.42 157.454 223.748 154.783 223.748 151.487C223.748 148.191 226.42 145.52 229.715 145.52C233.011 145.52 235.682 148.191 235.682 151.487Z" fill="#E34D4E"/>
<path d="M230.878 157.337C231.74 153.878 232.245 149.453 232.522 146.251C231.682 145.8 230.736 145.52 229.716 145.52C226.421 145.52 223.748 148.191 223.748 151.487C223.748 154.782 226.421 157.454 229.716 157.454C230.113 157.454 230.501 157.411 230.878 157.337Z" fill="#923A3A"/>
<path d="M192.451 151.487C192.451 154.783 195.122 157.454 198.418 157.454C201.713 157.454 204.385 154.783 204.385 151.487C204.385 148.191 201.713 145.52 198.418 145.52C195.122 145.52 192.451 148.191 192.451 151.487Z" fill="#E34D4E"/>
<path d="M197.256 157.337C196.394 153.878 195.888 149.453 195.611 146.251C196.451 145.8 197.398 145.52 198.418 145.52C201.713 145.52 204.385 148.191 204.385 151.487C204.385 154.782 201.713 157.454 198.418 157.454C198.02 157.454 197.632 157.411 197.256 157.337Z" fill="#923A3A"/>
<path d="M224.569 130.618H214.067H203.564L198.367 141.976L199.241 153.216C199.708 159.215 203.584 169.571 209.035 172.116C210.655 172.872 212.371 173.376 214.067 173.376C215.763 173.376 217.479 172.872 219.098 172.116C224.55 169.571 228.426 159.215 228.892 153.216L229.767 141.976L224.569 130.618Z" fill="#FF7E6D"/>
<path d="M219.455 144.874C219.455 144.874 222.979 145.861 226.967 144.874" stroke="#2B2B31" stroke-width="1.336" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M218.316 138.416C218.316 138.416 223.462 137.56 226.556 139.343" stroke="#2B2B31" stroke-width="2.672" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M208.68 144.874C208.68 144.874 205.156 145.861 201.168 144.874" stroke="#2B2B31" stroke-width="1.336" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M209.818 138.416C209.818 138.416 204.672 137.56 201.578 139.343" stroke="#2B2B31" stroke-width="2.672" stroke-miterlimit="10" stroke-linecap="round"/>
<g opacity="0.15">
<path opacity="0.15" d="M205.698 154.669C205.011 143.312 203.564 130.618 203.564 130.618L198.367 141.976L198.644 145.543C198.568 145.54 198.496 145.52 198.419 145.52C195.123 145.52 192.451 148.191 192.451 151.487C192.451 154.782 195.123 157.454 198.419 157.454C198.94 157.454 199.438 157.366 199.921 157.24C201.326 163.045 204.673 170.079 209.035 172.115C210.655 172.871 212.371 173.375 214.067 173.375C214.067 173.375 206.384 166.026 205.698 154.669Z" fill="#FFF6E7"/>
</g>
<path d="M249.944 372.024L131.175 335.99C115.406 331.205 98.8194 340.488 94.6514 356.432L94.4944 357.034C90.2004 373.461 100.852 390.056 117.578 392.979C161.49 400.655 241.084 413.709 282.973 415.304L300.631 427.077C303.297 428.854 306.837 428.561 309.174 426.37L309.265 426.284C312.044 423.679 312.186 419.317 309.584 416.537L306.83 413.595C306.83 413.595 304.374 401.637 292.25 396.512C280.126 391.386 278.988 378.858 276.14 372.024C273.293 365.19 255.07 361.774 249.944 372.024Z" fill="#A04040"/>
<path d="M94.6534 356.181C98.8214 340.237 115.407 330.954 131.176 335.74L171.454 347.959C165.386 355.528 157.384 370.892 157.143 399.407C142.438 397.017 128.833 394.695 117.58 392.728C100.854 389.805 90.2024 373.21 94.4964 356.784L94.6534 356.181Z" fill="#DAC4F9"/>
<g opacity="0.6">
<path opacity="0.6" d="M132.12 381.481C132.12 386.325 130.958 390.894 128.91 394.938C124.933 394.256 121.128 393.599 117.58 392.979C100.854 390.056 90.2024 373.46 94.4964 357.033L94.6534 356.431C95.0284 354.999 95.5064 353.624 96.0714 352.304C98.0774 351.878 100.156 351.651 102.289 351.651C118.764 351.651 132.12 365.006 132.12 381.481Z" fill="#CABC15"/>
</g>
<path d="M255.64 388.539C255.64 388.539 253.931 398.491 237.416 394.938" stroke="#8C3934" stroke-width="1.139" stroke-miterlimit="10"/>
<g opacity="0.3">
<path opacity="0.3" d="M266.137 383.982C266.137 383.982 240.832 382.843 187.301 373.294C143.932 365.558 131.211 343.36 127.943 335.21C129.022 335.408 130.101 335.663 131.176 335.989L249.945 372.023C255.071 361.773 273.293 365.189 276.141 372.023C278.989 378.857 280.126 391.385 292.25 396.511C304.374 401.636 306.829 413.595 306.829 413.595C306.829 413.595 294.364 405.623 286.483 403.915C278.602 402.206 266.137 383.982 266.137 383.982Z" fill="#542524"/>
</g>
<path d="M271.617 414.665C271.617 414.665 293.426 435.531 312.018 426.945C315.112 425.517 316.082 421.566 314.061 418.822C298.399 397.552 278.652 374.378 271.125 365.67C269.24 363.489 266.259 362.609 263.492 363.413L242.072 369.635L271.617 414.665Z" fill="#2B2B31"/>
<path d="M242.072 369.635L221.557 363.411L237.096 411.232L271.615 414.665L242.072 369.635Z" fill="#A6A6A6"/>
<path d="M178.19 372.024L296.96 335.99C312.728 331.205 329.314 340.488 333.482 356.432L333.639 357.034C337.934 373.461 327.282 390.056 310.556 392.979C266.644 400.655 187.05 413.709 145.161 415.304L127.502 427.077C124.836 428.854 121.297 428.561 118.96 426.37L118.868 426.284C116.09 423.679 115.948 419.317 118.551 416.537L121.305 413.595C121.305 413.595 123.759 401.637 135.884 396.512C148.008 391.386 149.147 378.858 151.995 372.024C154.841 365.19 173.065 361.774 178.19 372.024Z" fill="#FF7E6D"/>
<path d="M333.482 356.431C329.314 340.487 312.728 331.204 296.959 335.99L256.682 348.209C262.749 355.778 270.752 371.142 270.993 399.657C285.698 397.267 299.302 394.945 310.556 392.978C327.282 390.055 337.934 373.46 333.639 357.034L333.482 356.431Z" fill="#DAC4F9"/>
<g opacity="0.6">
<path opacity="0.6" d="M296.016 381.481C296.016 386.325 297.177 390.894 299.226 394.938C303.203 394.256 307.008 393.599 310.556 392.979C327.282 390.056 337.934 373.46 333.639 357.033L333.482 356.431C333.108 354.999 332.629 353.624 332.065 352.304C330.059 351.878 327.98 351.651 325.847 351.651C309.371 351.651 296.016 365.006 296.016 381.481Z" fill="#CABC15"/>
</g>
<g opacity="0.3">
<path opacity="0.3" d="M161.997 383.732C161.997 383.732 187.301 382.593 240.832 373.044C284.201 365.308 296.923 343.11 300.19 334.96C299.112 335.158 298.033 335.413 296.957 335.739L178.188 371.773C173.063 361.523 154.84 364.939 151.993 371.773C149.145 378.607 148.007 391.135 135.883 396.261C123.758 401.386 121.303 413.345 121.303 413.345C121.303 413.345 133.769 405.373 141.65 403.665C149.531 401.956 161.997 383.732 161.997 383.732Z" fill="#542524"/>
</g>
<path d="M207.961 154.482C207.321 154.482 206.945 155.224 207.338 155.729C209.121 158.018 211.482 159.418 214.076 159.418C216.671 159.418 219.031 158.018 220.814 155.729C221.207 155.224 220.831 154.482 220.191 154.482H207.961Z" fill="white"/>
<path d="M215.83 144.623V150.803C215.83 150.803 214.409 150 212.432 150.803" stroke="#2B2B31" stroke-width="0.821" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M219.455 131.998H220.975V122.332H219.455V131.998Z" fill="#FF7E6D"/>
<path d="M185.6 358.408C185.6 358.408 188.8 340.845 178.375 335.21" stroke="#542524" stroke-width="1.336" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M242.534 358.408C242.534 358.408 239.333 340.845 249.758 335.21" stroke="#542524" stroke-width="1.336" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M156.52 414.665C156.52 414.665 134.711 435.531 116.119 426.945C113.025 425.517 112.055 421.566 114.076 418.822C129.738 397.552 149.484 374.378 157.011 365.67C158.895 363.489 161.878 362.609 164.645 363.413L186.064 369.635L156.52 414.665Z" fill="#2B2B31"/>
<path d="M186.062 369.635L206.578 363.411L191.038 411.232L156.518 414.665L186.062 369.635Z" fill="#ECECEC"/>
<path d="M177.531 382.639C183.616 381.99 191.52 381.039 201.297 379.659L206.577 363.411L186.062 369.635L177.531 382.639Z" fill="#542524"/>
<path d="M163.054 400.142L151.153 418.28C150.412 419.41 149.088 420.012 147.749 419.83L147.671 419.82C145.093 419.47 143.781 416.534 145.24 414.379L157.411 396.411C158.19 395.26 159.597 394.71 160.949 395.028L161.019 395.044C163.283 395.576 164.329 398.197 163.054 400.142Z" fill="#FFF6E7"/>
<path d="M212.432 295.311H139.6L157.557 359.271H212.432H267.307L285.264 295.311H212.432Z" fill="#B0B0B0"/>
<path d="M267.307 368.588H157.557V359.272H267.307V368.588Z" fill="#8C8C8D"/>
<path d="M230.366 332.332C230.366 337.369 222.392 341.452 212.557 341.452C202.722 341.452 194.75 337.369 194.75 332.332C194.75 327.295 202.722 323.212 212.557 323.212C222.392 323.212 230.366 327.295 230.366 332.332Z" fill="#595963"/>
<path d="M285.264 291.068H139.6V295.311H285.264V291.068Z" fill="#595963"/>
<path d="M174.627 365.692H163.637V361.948H174.627V365.692Z" fill="#595963"/>
<path d="M190.49 365.692H179.5V361.948H190.49V365.692Z" fill="#595963"/>
<path d="M267.307 363.93H200.514" stroke="#050900" stroke-miterlimit="10"/>
<g opacity="0.4">
<path opacity="0.4" d="M139.6 294.311L157.557 358.272H196.647C219.856 331.362 233.958 294.311 233.958 294.311L233.911 290.068H139.6V294.311Z" fill="white"/>
</g>
<path d="M139.6 295.311H285.264" stroke="#050900" stroke-miterlimit="10"/>
</svg>

          </div>
      </div>
    </div>
  );
}